@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.main-heading{
  font-weight: 600;
  font-family: 'Patrick Hand', cursive;
}

.logout{
  background: #5b21b6;
}

code {
  font-family: 'Montserrat', sans-serif;
}
