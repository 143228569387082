:root {
  --background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%);
  --primary-color: #0091ad;
  --primary-color-md-light: #c9f2fa;
  --primary-color-light: #e5fbff;
  --main-font: "Montserrat", sans-serif;
  --bg-colour-lightgrey: white;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--main-font);
}

/* New hero section  */
.my-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--main-font);
  background-color: var(--bg-colour-lightgrey);
}

/* hero info  */
.hero-info {
  width: 62%;
}
.header-section {
  background-color: var(--bg-colour-lightgrey);
}
.hero-heading {
  font-family: var(--main-font);
  color: var(--primary-color);
  font-size: 50px;
  font-weight: 700;
  margin-left: 9vw;
  margin-top: 0rem;
  padding-top: 1rem;
  margin-bottom: -2rem;
  background-color: var(--bg-colour-lightgrey);
}

h1.short-intro {
  font-size: 2.9em;
  font-weight: 700;
  max-width: 53%;
  line-height: 62px;
}

p.intro-text {
  color: var(--primary-color);
  font-size: 2em;
  max-width: 68%;
  line-height: 41px;
  font-weight: 600;
  margin: 2rem 0;
  letter-spacing: -1px;
}

.action-btns {
  font-size: 1.55em;
  margin: 1rem 0;
}

a.get-started {
  background-color: var(--primary-color);
  color: white;
  padding: 7px 25px;
  border-radius: 13px;
  margin-right: 1.5rem;
}
a.get-noted {
  background-color: white;
  color: var(--primary-color);
  padding: 7px 25px;
  border-radius: 13px;
  border: 2px solid;
}
a.get-noted:hover {
  background-color: var(--primary-color-md-light);
  transition: 0.3s;
}
/* images  */
.hero-image {
  position: relative;
  bottom: 3rem;
}

img.yt-head {
  position: relative;
  top: 4em;
  right: 4em;
}

.loginbtn {
  position: absolute;
  padding: 1.5rem;
  background-color: rgba(233, 138, 101, 0.8);
  width: fit-content;
  border-radius: 20px;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  left: 40%;
}
.bg-primary-light {
  background-color: var(--primary-color-light);
}

.sub-info-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15%;
  margin: 0rem auto;
  padding: 1rem 0rem;
}

.info {
  font-family: var(--main-font);
  max-width: 30%;
  font-size: 18px;
  color: var(--primary-color);
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  max-width: 27%;
}

.image {
  width: 205px;
}

h2.image-title {
  font-family: var(--main-font);
  font-size: 29px;
  color: var(--primary-color);
  font-weight: 800;
  padding: 15px 0rem;
}

.footer {
  background-color: var(--primary-color);
  color: white;
  font-family: "Montserrat", sans-serif;
}

.dark-text {
  color: var(--primary-color);
}

.experience {
  font-family: var(--main-font);
  padding: 2rem 0rem;
}
/* media queries for medium screens  */
@media screen and (max-width: 1142px) {
  h1.short-intro {
    max-width: 70%;
  }
}

@media screen and (max-width: 860px) {
  .short-intro {
    font-size: 2.5em !important;
    line-height: 48px !important;
  }
  p.intro-text {
    font-size: 1.8em;
  }
  .hero-image {
    width: 30vw;
  }
  .hero-heading {
    padding-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 730px) {
  .short-intro {
    font-size: 2.2em !important;
  }
  p.intro-text {
    font-size: 1.6em;
  }
  .action-btns {
    font-size: 1.3em !important;
  }
}
@media screen and (max-width: 670px) {
  .short-intro {
    font-size: 1.9em !important;
  }
  p.intro-text {
    font-size: 1.5em;
  }
  .action-btns {
    font-size: 1.1em !important;
  }
  .hero-info {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .short-intro {
    font-size: 1.7em !important;
    line-height: 36px !important;
  }
  p.intro-text {
    font-size: 1.3em;
    line-height: 28px;
  }
  .action-btns {
    font-size: 1em !important;
  }
  a.get-started {
    margin-right: 0.6rem;
  }
}
@media screen and (max-width: 480px) {
  .my-hero {
    flex-direction: column;
  }
  .hero-info {
    width: 85%;
  }
  h1.short-intro,
  p.intro-text {
    max-width: 100%;
  }
  .hero-heading {
    font-size: 37px;
  }
}
/* media queries for smaller screens  */

@media screen and (max-width: 820px) {
  .info {
    max-width: 40%;
  }
}

@media screen and (max-width: 620px) {
  .sub-info-sec {
    flex-direction: column;
    margin: 1rem auto;
  }
  .sub-info-sec-reverse {
    flex-direction: column-reverse;
  }
  .download-sec {
    padding-top: 2rem !important;
    padding-bottom: 0rem !important;
  }
  .info {
    max-width: 60%;
    text-align: center;
  }
  .gradient {
    display: none;
  }
  .hero-text-sec {
    position: static;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #0091ad;
    max-width: 70%;
    line-height: 36px;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 1rem;
    height: 12rem;
  }
  .download-sec {
    padding: 0 !important;
  }
}
