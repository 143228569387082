:root {
    --primary-color: #0091ad;
    --primary-dark-md: #368a9b;
    --primary-light-md: #7fcddf;
    --primary-light: #cff2fa;
  }

.timestamps-list-div {
    width: 73%;
    margin: 0 auto;
    margin-bottom: 7rem;
}

.notes-timeline-container {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: aliceblue;
}

.video-page{
    transition: ease-in-out .5s;
}

.notes-container-main {
    width: 45%;
    height: 80vh;
    background-color: white;
    /* border: 3px solid var(--primary-light-md); */
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0px 1px 10px 8px rgb(166 166 166);
}
section.notes-and-video.flex {
    display: flex;
    justify-content: center;
    gap: 2rem;
}


button.MuiButton-root {
    background-color: white;
    align-self: flex-end;
}



